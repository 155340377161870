import { createApp } from 'vue';
import { createManager } from '@vue-youtube/core';
import { createPinia } from 'pinia';
import Base from './layouts/Base.vue';
import router from './router';
import locale from './lang';
import Toast, { POSITION } from 'vue-toastification';

const pinia = createPinia();
const app = createApp(Base)
    .use(createManager())
    .use(locale)
    .use(pinia)
    .use(router)
    .use(Toast, {
        position: POSITION.BOTTOM_RIGHT,
        timeout: 3000,
        closeOnClick: true,
        icon: true,
        transition: 'Vue-Toastification__fade',
        maxToasts: 5,
    })
    .directive('click-outside', {
        beforeMount(el, binding) {
            el.clickOutsideEvent = function (event) {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value();
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        }
    });

router.isReady()
    .then(() => {
        app.mount('#app');
    });
