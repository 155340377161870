import { createI18n } from 'vue-i18n';
import it from './it'

const config = {
    locale: 'it',
    fallbackLocale: 'it',
    messages: {
        it
    }
}
const i18n = createI18n(config);
export default i18n;
