import axios from 'axios';

export async function csrfCookie() {
    const url = route('sanctum.csrf-cookie');
    await axios.get(url);
}

export async function login(username = '', password = '') {
    const url = route('auth.login');
    return await axios.post(
        url,
        {
            'email': username,
            'password': password
        },
    )
}

export async function me() {
    const url = route('me')
    return await axios.get(
        url
    );
}
