import { createRouter, createWebHistory } from 'vue-router';
import {
    redirectAuthenticatedUser,
    redirectNonAdminUser,
    redirectUnauthenticatedUser,
    refreshAuthStatus
} from "./middlewares";
const Home = () => import('../pages/Home.vue');
const Login = () => import('../pages/Login.vue');
const Signup = () => import('../pages/Signup.vue');
const Dashboard = () => import('../layouts/Dashboard.vue');
const DashboardHome = () => import('../pages/dashboard/Home.vue');
const Calculator = () => import('../pages/dashboard/Calculator.vue');
const Video = () => import('../pages/dashboard/Video.vue');
const LibreriaTecnica = () => import('../pages/dashboard/LibreriaTecnica.vue');
const Contents = () => import('../pages/dashboard/Contents.vue');
const Calculation = () => import('../pages/dashboard/calculations/Calculation.vue');
const Calculations = () => import('../pages/dashboard/calculations/Calculations.vue');
const RegistrationSuccess = () => import('../pages/RegistrationSuccess.vue');
const Validating = () => import('../pages/Validating.vue');
const NotFound = () => import('../pages/NotFound.vue');
const ForgotPassword = () => import('../pages/ForgotPassword.vue');
const RefreshPassword = () => import('../pages/RefreshPassword.vue');
const Profile = () => import('../pages/admin/Profile.vue');
const Superadmin = () => import('../pages/superadmin/Superadmin.vue');
const Stats = () => import('../pages/superadmin/Stats.vue');
const AdminVideo = () => import('../pages/superadmin/Video.vue');
const AdminCalculations = () => import('../pages/superadmin/Calculations.vue');
const AdminCalculation = () => import('../pages/superadmin/Calculation.vue');
const Files = () => import('../pages/superadmin/Files.vue');
const Contacts = () => import('../pages/dashboard/Contacts.vue');
const AdminUsers = () => import('../pages/superadmin/Users.vue');

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active-parent',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            children: [
                {
                    path: '',
                    name: 'home.login',
                    component: Login,
                    meta: {
                        title: 'Eurotherm - Login'
                    },
                    beforeEnter: [redirectAuthenticatedUser]
                },
                {
                    path: '/signup',
                    name: 'home.signup',
                    component: Signup,
                    meta: {
                        title: 'Eurotherm - Registrazione'
                    },
                    beforeEnter: [redirectAuthenticatedUser]
                },
                {
                    path: '/success',
                    name: 'home.signup.success',
                    component: RegistrationSuccess,
                },
                {
                    path: '/validating/:token',
                    name: 'home.signup.validating',
                    component: Validating,
                    props: true
                },
                {
                    path: '/forgot',
                    name: 'home.forgot-password',
                    component: ForgotPassword
                },
                {
                    path: '/refresh/:token',
                    name: 'home.refresh-password',
                    component: RefreshPassword,
                    props: true
                }
            ]
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            children: [
                {
                    path: '',
                    name: 'dashboard.home',
                    component: DashboardHome,
                },
                {
                    path: 'profile',
                    name: 'dashboard.profile',
                    component: Profile,
                    beforeEnter: [redirectUnauthenticatedUser]
                },
                {
                    path: 'calculator',
                    name: 'dashboard.calculator',
                    component: Calculator,
                    beforeEnter: [redirectUnauthenticatedUser]
                },
                {
                    path: 'video',
                    name: 'dashboard.video',
                    component: Video
                },
                {
                    path: 'library',
                    name: 'dashboard.library',
                    component: LibreriaTecnica
                },
                {
                    path: 'contents',
                    name: 'dashboard.contents',
                    component: Contents
                },
                {
                    path: 'calculations',
                    name: 'dashboard.calculations',
                    component: Calculations,
                    beforeEnter: [redirectUnauthenticatedUser],
                },
                {
                    path: 'calculation/:id',
                    name: 'dashboard.calculation',
                    component: Calculation,
                    beforeEnter: [redirectUnauthenticatedUser],
                    props: true
                },
                {
                    path: 'contacts',
                    name: 'dashboard.contacts',
                    component: Contacts,
                    beforeEnter: [redirectUnauthenticatedUser],
                },
                {
                    path: 'admin',
                    name: 'dashboard.admin',
                    component: Superadmin,
                    beforeEnter: [redirectUnauthenticatedUser, redirectNonAdminUser],
                    children: [
                        {
                            path: 'stats',
                            name: 'dashboard.admin.stats',
                            component: Stats
                        },
                        {
                            path: 'videos',
                            name: 'dashboard.admin.videos',
                            component: AdminVideo
                        },
                        {
                            path: 'calculations',
                            name: 'dashboard.admin.calculations',
                            component: AdminCalculations
                        },
                        {
                            path: 'calculation/:id',
                            name: 'dashboard.admin.calculation',
                            component: AdminCalculation,
                            props: true
                        },
                        {
                            path: 'files',
                            name: 'dashboard.admin.files',
                            component: Files,
                        },
                        {
                            path: 'users',
                            name: 'dashboard.admin.users',
                            component: AdminUsers,
                        }
                    ]
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            component: NotFound
        }
    ]
});

router.beforeEach(refreshAuthStatus);

export default router;
