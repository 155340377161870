import { useAuthStore } from "../store/auth";

export async function refreshAuthStatus(to, from, next) {
    const auth = useAuthStore();
    await auth.me();
    next();
}

export async function redirectAuthenticatedUser(to) {
    const auth = useAuthStore();
    if (auth.status === 'authenticated') {
        return {
            name: 'dashboard.home'
        }
    }
}

export async function redirectUnauthenticatedUser(to) {
    const auth = useAuthStore();
    if (auth.status !== 'authenticated') {
        return {
            name: 'home.signup',
            query: { redirect: to.fullPath }
        }
    }
}

export async function redirectNonAdminUser(to) {
    const auth = useAuthStore();

    if (auth.status === 'unauthenticated' || !['admin', 'superadmin'].includes(auth.user.role)) {
        return {
            name: 'dashboard.home',
        }
    }
}
