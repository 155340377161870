import { defineStore } from 'pinia';
import { csrfCookie, login, me } from '../api/auth';
import router from '../router';
import axios from "axios";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        status: 'unauthenticated',
        user: {},
        statusEditProfile: '',
        statusEditPassword: '',
    }),
    getters: {
        fullName: state => state.user.first_name + ' ' + state.user.last_name,
        firstLetter: state => state.user.first_name.charAt(0)
    },
    actions: {
        async login( email, password ) {
            await csrfCookie();
            this.status = 'pending';
            try {
                const res = await login(email, password);
                this.user = res.data;
                this.status = 'authenticated';
                await router.push({ name: 'dashboard.home' })
            } catch (e) {
                this.status = 'error';
            }
        },
        async me( ) {
            await csrfCookie();
            try {
                const res = await axios.get(route('me'));
                this.status = 'authenticated';
                this.user = res.data;
            } catch (e) {
                this.status = 'unauthenticated';
            }
        },
        editProfileInfo() {
            axios.post(
                route('profile.update', this.user.id),
                this.user
            )
                .then(response => response.status === 204
                        ? this.statusEditProfile = 'success'
                        : this.statusEditProfile = 'error'
                )
                .catch(error => this.statusEditProfile = 'error');
        },
        editPassword(oldPassword, newPassword) {
            axios.post(
                route('profile.password', this.user.id),
                {
                    old_password: oldPassword,
                    new_password: newPassword
                }
            )
                .then(response => {
                    return response.status === 204
                        ? this.statuseditPassword = 'success'
                        : this.statuseditPassword = 'error'
                })
                .catch(error => this.statuseditPassword = 'error');
        }
    },
})
