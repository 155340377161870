export default {
    'home': {
        'desc': {
            'title': 'Vuoi rendere il tuo progetto più efficiente?',
            'paragraph-1': 'Il Virtual Tech Studio è il luogo dove, come professionista, puoi accedere a servizi esclusivi, per gestire in modo pratico e veloce ogni tuo progetto',
            'paragraph-2': 'Non sei un professionista? Clicca il tasto in basso per accede a contenuti selezionati',
            'medias': {
                '1': 'Niente più lunghe attese per la definizione dei calcoli preliminari, grazie al nostro calcolatore tecnico per i professionisti',
                '2': 'Niente più confusione sul miglior sistema da utilizzare per le tue necessità di progetto',
                '3': 'Documentazione tecnica a disposizione e scaricabile immediatamente',
                '4': 'Nessun ping-pong telefonico per comunicare con noi e con il tuo referente Eurotherm di fiducia: ora puoi fissare il tuo appuntamento quando vuoi',
                '5': 'Nessuna incomprensione con clienti e installatori, grazie ai contenuti informativi a disposizione',
            },
            'cta-public': 'Vai alla pagina pubblica'
        },
        'sign-in': {
            'title': 'Registrazione',
            'privacy': 'Registrandoti, confermi di aver letto e accettato la ',
            'privacy-cta': 'Informativa sulla Privacy',
            'button': 'Registrati'
        },
        'login': {
            'title': 'Accedi',
            'privacy': 'Registrandoti, confermi di aver letto e accettato la ',
            'privacy-cta': 'Informativa sulla Privacy',
            'button': 'Accedi',
        }
    },
    'errors': {
        'login': {
            'data': 'Attenzione, i tuoi dati non sono corretti oppure non hai ancora verificato l\'account, riprova'
        }
    },
    'dashboard': {
        'title': 'Eurotherm - Dashboard'
    }
}
